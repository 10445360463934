// ERROR:
//  GET https://gofordesi-development.s3.amazonaws.com/m6p36lipmbljfbn0z0sk7np52sy8…502f80b…&timestamp=1721656915780 403 (Forbidden)
// show:1 Access to XMLHttpRequest at 'https://gofordesi-development.s3.amazonaws.com/m6p36lipmbljfbn0z0sk7np52sy8…Signature=b4b297f…' from origin 'http://localhost:9292' has been blocked by CORS policy: No 'Access-Control-Allow-Origin' header is present on the requested resource.

// SOLUTION:
// https://www.dofactory.com/html/img/crossorigin
// image flagged with anonymous because the image is moved to the canvas witch is a client domain and not the server origin 

import { Controller } from "stimulus";
import Cropper from "cropperjs";

export default class extends Controller {
  static targets = ["cropperSource", "cropperOutput"];

  connect() {
    this.initCropper();
  }

  initCropper() {
    this.cropper = new Cropper(this.cropperSourceTarget, {
      aspectRatio: 300 / 350,
      dragMode: 'move',
      minContainerWidth: 350,
      minContainerHeight: 300,
      maxCanvasWidth: 0,
      maxCanvasHeight: 0,
    });
  }

  click() {
    const croppedCanvas = this.cropper.getCroppedCanvas();
    const croppedImage = croppedCanvas.toDataURL("image/jpeg");

    this.postToAPI(croppedImage);
  }

  async postToAPI(croppedData) {
    try {
      const photo_id = this.data.get("memberPictureId");
      const dataURL = croppedData;
      const response = await fetch(`/profile/crop_image/?member_picture=${photo_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        },
        body: JSON.stringify({
          photo: {
            member_picture: photo_id,
            cropped_image: dataURL,
          },
        }),
      });

      const result = await response.json();

      if (response.ok) {
        if (result.notice) {
          console.log(result.notice);
          window.location.reload(true);
        }
      }
      else {
        console.error('Error:', result.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
}
