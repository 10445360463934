import { Controller } from 'stimulus'
import { Select } from "mdb-ui-kit";

export default class extends Controller {
  resetSearch() {
    const selects = document.querySelectorAll('select');

    selects.forEach(select => {
      const selectInstance = Select.getInstance(select);

      select.options.forEach(option => {
        option.removeAttribute('selected');
      })

      selectInstance.setValue('1');
    });
  }
}